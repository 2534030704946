import { useState } from "react";
import { FAQPageJsonLd } from "next-seo";

function FAQ({ data }) {
  const [activeDefault, setActiveDefault] = useState(0);
  const defaultAccordion = data.list;
  return (
    <>
      <FAQPageJsonLd
        mainEntity={defaultAccordion.map((item) => {
          return {
            questionName: item.Question,
            acceptedAnswerText: item.Answer,
          };
        })}
      />

      <div className="container mt-3">
        <div className="row">
          <div className="section-head style-1 mb-4">
            <span className="sub-title bgl-primary m-b20 text-primary">
              { data.title }
            </span>
            <h2 className="title">
            { data.sub_title }
            </h2>
          </div>
          <div
            className="dlab-accordion col-12"
            id="accordionFaq"
            defaultactivekey="0"
          >
            {defaultAccordion.map((d, i) => (
              <div className="card" key={i}>
                <div
                  eventkey={`${i}`}
                  className="card-header"
                  onClick={() => setActiveDefault(activeDefault === i ? -1 : i)}
                >
                  <h2 className="h5 dlab-title">
                    <a
                      className={`${activeDefault === i ? "" : "collapsed"}`}
                      onClick={() =>
                        setActiveDefault(activeDefault === i ? -1 : i)
                      }
                    >
                      {" "}
                      {d.Question}
                    </a>
                  </h2>
                </div>
                <div
                  className={`${
                    activeDefault === i ? "collapsed show" : "collapsed"
                  }`}
                  eventkey={`${i}`}
                  onClick={() => setActiveDefault(activeDefault === i ? -1 : i)}
                >
                  <div className="card-body">
                    <p
                      className="m-b0"
                      dangerouslySetInnerHTML={{ __html: d.Answer }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
