import Link from 'next/link';
import Image from 'next/image';

function AboutUs({item}) {
    return (
      <>  
          <section className="content-inner">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-6 m-b30">
                          <div className="dz-media">
                          <Image width={570} height={433} src={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.image} className="move-1" alt={item.title}/>
                          </div>
                      </div>
                      <div className="col-lg-6 m-b30">
                          <div className="section-head style-1 mb-4">
                              <span className="sub-title bgl-primary m-b20 text-primary">{item.sub_title}</span>
                              <h2 className="title">{item.title}</h2>
                          </div>
                          <p>{item.description}</p>
                         <Link legacyBehavior href={item.action_button_URL}><a  className="btn btn-link d-inline-flex align-items-center"><i className="fa fa-angle-left m-l10"></i>{item.action_button_title}</a></Link> 
                      </div>
                  </div>
              </div>
          </section>
      </>
    )
  }
  
  export default AboutUs;