import Image from 'next/image';

function Features({item}) {
    return (
      <>
        <section className="content-inner-2">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-xl-6 col-lg-7">
						<div className="section-head style-1">
							<span className="sub-title bgl-primary m-b20 text-primary">{item.sub_title}</span>
							<h2 className="title">{item.title}</h2>
						</div>
						<div className="section-wraper-one">
							
							{item.blocks.map((block,index)=>{
								return(<div key={index} className="icon-bx-wraper style-2 left m-b30">
											<div className={"icon-bx-md radius bg-white text-"+block.color}> 
												<a href="#" className="icon-cell">
													<i className={block.icon}></i>
												</a> 
											</div>
											<div className="icon-content">
												<h3 className="dlab-title">{block.title}</h3>
												<p>{block.description}</p>
											</div>
										</div>);
							})}
						</div>
					</div>
					<div className="col-xl-6 col-lg-5">
						<div className="dlab-media">
						<Image width={570} height={570} src={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.image} className="move-2" alt=""/>
						</div>
					</div>
				</div>
			</div>
		</section>
      </>
    )
  }
  
  export default Features;