import AboutUs from "../element/builder/aboutUs";
import Counter from "../element/builder/counter";
import Cta from "../element/builder/cta";
import FAQ from "../element/builder/faq";
import Login from "../element/builder/login";
import Features from "../element/builder/our-features";
import Pricing from "../element/builder/pricing";
import Register from "../element/builder/register";
import Service from "../element/builder/service";
import Slider from "../element/builder/slider";
import Text from "../element/builder/text";

function Builder({item}) {
    if(item.type == 'faq'){
        return (<FAQ data={item.data} />);
    }
    if(item.type == 'home_slider'){
        return (<Slider item={item.data} />);
    }
    if(item.type == 'page_text'){
        return (<Text item={item.data} />);
    }
    if(item.type == 'service'){
        return (<Service item={item.data} />);
    }
    if(item.type == 'about'){
        return (<AboutUs item={item.data} />);
    }
    if(item.type == 'features'){
        return (<Features item={item.data} />);
    }
    if(item.type == 'call_to_action'){
        return (<Cta item={item.data} />);
    }
    if(item.type == 'counter'){
        return (<Counter item={item.data} />);
    }
    if(item.type == 'price_table'){
        return (<Pricing item={item.data} />);
    }
    if(item.type == 'register_form'){
        return (<Register item={item.data} />);
    }
    if(item.type == 'login_form'){
        return (<Login item={item.data} />);
    }
}

export default Builder;
