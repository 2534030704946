import Link from "next/link";
import Image from "next/image";


function Slider ({item}) {
    return (
      <>
        <div className="banner-one" style={{"backgroundImage":"url(/images/main-slider/slider1/pic2.png)"}}>
			<div className="container">
				<div className="banner-inner">
					<div className="img1"><Image width={760} height={509} loading="lazy" src="/images/main-slider/slider1/pic3.png" alt=""/></div>
					<div className="img2"><Image width={309} height={272} loading="lazy" src="/images/main-slider/slider1/pic4.png" alt=""/></div>
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="banner-content">
								<p className=" sub-title text-primary">{item.title}</p>
								<h1 className=" maintitle">{item.sub_title}</h1>
								<p className=" m-b30">{item.description}</p>
								<Link legacyBehavior href={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.file}><a className="btn btn-primary rounded-md mr-5">{item.file_button_title} <i className="fa fa-file m-r10"></i></a></Link> 
								<Link legacyBehavior href={item.action_button_URL} ><a  className=" btn btn-link d-inline-flex align-items-center" ><i className="fa fa-angle-left m-l10"></i>{item.action_button_title}</a></Link>
							</div>
						</div>
						<div className="col-md-6">
							<div className="dz-media move-box">
								<Image width={570} height={488} className="move-1" loading="lazy" src={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.image} alt={item.title}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    )
  }
  
  export default Slider;