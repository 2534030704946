
import { useState } from "react";
import Link from 'next/link';

function Pricing({item}) {
    const [open, setOpen] = useState("p2")
  return (
    <>
        <section className="mb-5" style={{backgroundImage: "url(/images/background/bg20.png)", backgroundRepeat: "no-repeat", backgroundSize: "100%", backgroundPosition:"center"}}>
            <div className="container">
                <div className="section-head style-1 text-center">
                    <span className="sub-title">{item.sub_title}</span>
                    <h2 className="title">{item.title}</h2>
                </div>
                <div className="row pricingtable-wraper-2">
                    {item.blocks.map((block,index)=>{
                        return(<div key={index} className="col-lg-4 col-md-6">
                        <div className={`${open === "p"+index ? "pricingtable-wrapper active style-1 m-b30" : "pricingtable-wrapper style-1 center m-b30"}`} onMouseOver={() => setOpen("p"+index)}>
                            <div className="pricingtable-inner">
                                <div className="pricingtable-title">
                                    <h3 className="title">{block.title}</h3>
                                </div>
                                <div className="pricingtable-price"> 
                                    <span className="h2 pricingtable-bx">{block.price}<small>/ شهرياً</small></span>
                                </div>
                                <p className="text">{block.under_price}</p>
                                <ul className="pricingtable-features">
                                    {block.feature_list.map((listItem,index)=>{
                                        return(<li key={index}>{listItem.line}</li>);
                                    })}
                                </ul>
                                <div className="pricingtable-footer"> 
                                    <Link legacyBehavior href={block.action_button_URL}><a className="btn btn-primary rounded-md">{block.action_button_title} <i className="fa fa-angle-left m-r10"></i></a></Link> 
                                </div>
                            </div>
                        </div>
                            </div>);
                    })}

                </div>
            </div>
        </section>
    </>
  )
}

export default Pricing;