import Counterup from '../../component/counterup';
function Counter({item}) {
    return (
      <>
		<section className="counter-wraper-2 overlay-gradient-dark"> 
			<div className="counter-inner content-inner-3" style={{"backgroundImage":"url(/images/background/bg14.png)","backgroundPosition":"center","backgroundRepeat":"no-repeat"}}>
				<div className="container">
					<div className="row">
						
					{item.blocks.map((block,index)=>{
						return(<div key={index} className="col-lg-3 col-sm-6 m-b30">
							<div className="dlab-content-bx style-3 text-center">
								<div className="icon-content">
									<span className="h2 m-b0 text-primary"><Counterup count={Number(block.count)}/>+</span>
									<span className="title">{block.title}</span>
									<div className="icon-md text-primary"> 
										<span className="icon-cell">
											<i className={block.icon}></i>
										</span> 
									</div>
								</div>
							</div>
							</div>);
					})}
						
					</div>
				</div>
			</div>
		</section>
      </>
    )
  }
  
  export default Counter;