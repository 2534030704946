import Link from "next/link";


function Cta({item}) {
  return (
    <>
      <section style={{"backgroundImage":"url(/images/background/bg5.jpg)","backgroundSize":"cover"}}>
			<div className="container">
				<div className="row action-box style-1 align-items-center">
					<div className="col-xl-7 col-lg-8 col-md-8">
						<div className="section-head style-1">
							<span className="sub-title bgl-primary m-b20 text-primary">{item.sub_title}</span>
							<p className="h2 title">{item.title}</p>
						</div>
					</div>
					<div className="col-xl-5 col-lg-4 col-md-4 text-right m-b30">
						<Link legacyBehavior href={item.action_button_URL}><a className="btn btn-primary rounded-md mr-5">{item.action_button_title} <i className="fa fa-angle-left m-r10"></i></a></Link> 
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default Cta;