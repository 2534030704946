import Link from 'next/link';
import { useEffect, useState } from 'react';
import Image from "next/image";

function Header() {
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState("home");
	 /* for class fixed  */
	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);
	
    return (
      <>
        {/* <!-- Header --> */}
        <header className="site-header header-transparent text-black mo-left" id="fix-header">
            {/* <!-- Main Header --> */}
            <div className={`sticky-header main-bar-wraper navbar-expand-lg ${scroll ? "is-fixed" : ""}`}>
                <div className="main-bar clearfix ">
                    <div className="container clearfix">
                        {/* <!-- Website Logo --> */}
                        <div className="logo-header mostion logo-dark">
                           <Link legacyBehavior href="/"><a><Image width={150} height={65} src="/images/logo.png" alt=""/></a></Link>
                        </div>
                        {/* <!-- Nav Toggle Button --> */}
                        <button className={`navbar-toggler collapsed navicon justify-content-end ${show ? "open" : ""}`} onClick={() => setShow(!show)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        {/* <!-- Extra Nav --> */}
                        <div className="extra-nav">
                            <div className="extra-cell">
                                <Link legacyBehavior href="/register"><a className="btn btn-link d-inline-flex align-items-center"><i className="fa fa-angle-left m-l10"></i>التسجيل</a></Link>
                            </div>
                        </div>
                        <div className={`header-nav navbar-collapse collapse justify-content-end ${show ? "show" : ""}`} id="navbarNavDropdown">
                            <div className="logo-header">
                                <Link legacyBehavior href="/"><a><Image width={150} height={65} src="/images/logo.png" alt=""/></a></Link>
                            </div>
                            <ul className="nav navbar-nav navbar">	
                                <li><Link legacyBehavior href="/"><a>الرئيسية</a></Link></li>
                                <li><Link legacyBehavior href="/about-us"><a>عن eSmart</a></Link></li>
                                <li><Link legacyBehavior href="/blog"><a>المدونة</a></Link></li>
                                <li><Link legacyBehavior href="/faq"><a>اسئلة شائعة</a></Link></li>
                                <li><Link legacyBehavior href="/login"><a>تسجيل دخول</a></Link></li>
                            </ul>
                            <div className="dlab-social-icon">
                                <ul>
                                    <Link legacyBehavior href="https://en-gb.facebook.com/"><a className="fa fa-facebook"></a></Link>
                                    <Link legacyBehavior href="https://twitter.com/login?lang=en"><a className="fa fa-twitter"></a></Link>
                                    <Link legacyBehavior href="https://www.linkedin.com/login"><a className="fa fa-linkedin"></a></Link>
                                    <Link legacyBehavior href="https://www.instagram.com/"><a className="fa fa-instagram"></a></Link>
                                </ul>
                            </div>		
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Main Header End --> */}
        </header>
        {/* <!-- Header End --> */}
        
      </>
    )
  }
  
  export default Header;

  