import React, { useState } from 'react';

function Login({item}) {
	const [company, setCompany] = useState('no');
	
	const handleChange = (e) => {
		setCompany(e.target.value);
	};

	const loginComany = () => {
		if(company!='no')
		{
			window.location.assign('https://'+company+'.esmart.sa');
		}
	};

    return (
      <>
        <section className="content-inner-3 bg-primary" style={{"backgroundImage":"url(/images/background/bg13.png)","backgroundRepeat":"no-repeat","backgroundSize":"cover"}}>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-4 col-md-5 wow fadeInLeft">
						<div className="dlab-media m-b30">
							<img src={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.image} className="move-2" alt={item.sub_title}/>
						</div>
					</div>
					<div className="col-lg-8 col-md-7">
						<div className="section-head style-1 text-white">
							<span className="sub-title bgl-light m-b20">{item.sub_title}</span>
							<h2 className="title m-b15">هل أنت جاهز للتحكم في عقودك؟</h2>
							<p>{item.title}</p>
						</div>
						<div className="dlab-subscribe style-2 max-w500">
							<div className="form-group">
								<div className="input-group">
									<input name="companyName" required="required" type="text"  onChange={handleChange} className="form-control" placeholder="الشركة"/>
									<div className="input-group-addon">
										<button name="submit" value="Submit" type="submit" onClick={loginComany} className="btn rounded-xl shadow btn-primary">دخول</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
      </>
    )
  }
  
  export default Login;