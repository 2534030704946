import Link from 'next/link';

function Footer() {
  return (
    <>
      {/* <!-- Footer --> */}
    <footer className="site-footer style-1" id="footer" style={{"backgroundImage":"url(/images/background/bg10.png)"}}>
		<div className="footer-top">
            <div className="container">
				<div className="footer-info wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
					<div className="row align-items-center">
						
						<div className="col-xl-6 col-md-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
							<div className="widget widget_about">
								<h5 className="footer-title">عن eSmart</h5>
								<p>eSmart تعمل على تحسين كفاءة عملياتك القانونية وتتناول إدارة العقود بشكل شامل لتسهيل نتائج أعمال أفضل.</p>
								<div className="dlab-social-icon">
									<ul>
										<li><a className="fa fa-facebook" target="_blank" title="facebook" href="https://www.facebook.com/esmartcomsaco/"></a></li>
										<li><a className="fa fa-instagram" target="_blank" title="instagram" href="https://www.instagram.com/esmartcomsa/?hl=en"></a></li>
										<li><a className="fa fa-twitter" target="_blank" title="twitter" href="https://twitter.com/esmartcomsa"></a></li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-md-4 col-sm-6">
							<div className="icon-bx-wraper left m-b10">
								<div className="icon-lg"> 
									<Link legacyBehavior href="tel:+966504449747">
										<a className="icon-cell" href='tel:+966504449747' title="phone">
											<i className="flaticon-email"></i>
										</a> 
									</Link>	
								</div>
								<div className="icon-content">
									<p>
										<Link legacyBehavior href="tel:+966504449747"><a className="text-white" href="tel:+966504449747" title="phone">+966 50 444 9747</a></Link>
										<br/><Link legacyBehavior href="mailto:care@esmart.com.sa"><a className="text-white" href="mailto:care@esmart.com.sa">care@esmart.com.sa</a></Link>
										<br/><Link legacyBehavior href="mailto:sales@esmart.com.sa"><a className="text-white" href="mailto:sales@esmart.com.sa">sales@esmart.com.sa</a></Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-md-4 col-sm-6">
							<div className="icon-bx-wraper left m-b10">
								<div className="icon-lg"> 
									<Link legacyBehavior href="https://goo.gl/maps/fr49aRTpHM9h1vxp7" target="_blank">
										<a className="icon-cell" href="https://goo.gl/maps/fr49aRTpHM9h1vxp7" title="address" target="_blank">
											<i className="flaticon-location"></i>
										</a>
									</Link>	
								</div>
								<div className="icon-content">
									<p>
									<Link legacyBehavior href="https://goo.gl/maps/fr49aRTpHM9h1vxp7">
										<a className="text-white" href="https://goo.gl/maps/fr49aRTpHM9h1vxp7" title="address" target="_blank">
										eSmart · Uthman Ibn Affan Branch Rd, Al Falah, Riyadh 13314, Saudi Arabia
										</a>
									</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
        {/* <!-- footer bottom part --> */}
        <div className="footer-bottom wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center"> 
						<span className="copyright-text">Copyright © 2023 <a href="https://esmart.sa" target="_blank" title="eSmart">eSmart</a>. All rights reserved.</span> 
					</div>
                </div>
            </div>
        </div>
    </footer>
    {/* <!-- Footer End --> */}
    </>
  )
}

export default Footer;