import { useEffect, useState } from "react";
import Link from 'next/link';

function Service({item}) {
  const [open, setOpen] = useState("p2");
  return (
    <>
      <section
        className="content-inner-2"
        style={{ backgroundImage: "url(/images/background/bg1.png)" }}
      >
        <div className="container">
          <div className="section-head style-1 text-center">
            <span className="sub-title bgl-primary m-b20 text-primary">
              {item.sub_title}
            </span>
            <h2 className="title">{item.title}</h2>
          </div>
          <div className="row">
            
				{item.blocks.map((block,index)=>{
          return(
            <div
              key={index}
              className="col-lg-4 col-md-6"
            >
              <div
                className={`${
                  open === "p1"
                    ? "icon-bx-wraper style-1 box-hover text-center m-b30 active"
                    : "icon-bx-wraper style-1 box-hover text-center m-b30"
                }`}
                onMouseOver={() => setOpen("p1")}
              >
                <div className={"icon-bx-md radius bg-"+block.color+" shadow-"+block.color}>
					<Link legacyBehavior href="#">
					  <a className="icon-cell">
						<i className={block.icon}></i>
					  </a>
					</Link>  
                </div>
                <div className="icon-content">
                  <h3 className="dlab-title">{block.title}</h3>
                  <p>
                    {block.description}
                  </p>
                </div>
              </div>
            </div>);
        })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Service;
