function Text({item}) {
    return (
      <>  
          <section className="content-inner">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-12">
                          <div className="section-head style-1 mb-4">
                              <h2 className="title">{item.title}</h2>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      </div>
                  </div>
              </div>
          </section>
      </>
    )
  }
  
  export default Text;