import ReCAPTCHA from "react-google-recaptcha";
import Image from 'next/image';
import { useRef,useState } from "react";


function Register({item}) {
    const [sendSucess,setSendSucess] = useState(false);
    const [sendError,setSendError] = useState(false);
    const [sendLoading,setSendLoading] = useState(false);
    
    const recaptchaRef = useRef('');
    const onSubmit = async (event) => {
        event.preventDefault();
        setSendLoading(true);
        setSendError(false);
        setSendSucess(false);

        const data = {
            domain: event.target.domain.value,
            companyName: event.target.companyName.value,
            fullName: event.target.fullName.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            app: event.target.app.value,
            "g-recaptcha-response": recaptchaRef.current.getValue()
        }
        
        const JSONdata = JSON.stringify(data)
    
        const endpoint = 'https://backend.esmart.sa/api/register'
    
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'eSmartWebSite'
          },
          body: JSONdata,
        }
    
        const response = await fetch(endpoint, options)
    
        const result = await response.json()
        if(result.code == 200){
            setSendLoading(false);
            setSendError(false);
            setSendSucess(true);
        }else{
            setSendLoading(false);
            setSendError(true);
            setSendSucess(false);
        }
      }

    return (
		<>
        <section className="" style={{ "backgroundImage": "url(/images/background/bg1.png)" }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-7 m-b30">
                        <div className="section-head style-1">
                            <span className="sub-title bgl-primary m-b20 text-primary">{item.sub_title}</span>
                            <h2 className="title">{item.title}</h2>
                        </div>
                        <form className="dlab-form dzForm" onSubmit={onSubmit}>
                            <div className="dzFormMsg"></div>
                            <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-user"></i></span>
                                        </div>
                                        <input name="fullName" type="text" className="form-control" required placeholder="الاسم بالكامل" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-user"></i></span>
                                        </div>
                                        <input name="companyName" type="text" className="form-control" required placeholder="اسم الجهة" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-user"></i></span>
                                        </div>
                                        <input name="domain" type="text" required className="form-control" placeholder="الدومين" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-envelope"></i></span>
                                        </div>
                                        <input name="email" type="text" required className="form-control" placeholder="البريد الإلكتروني" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-phone"></i></span>
                                        </div>
                                        <input name="phone" type="text" required className="form-control" placeholder="الهاتف" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="la la-list"></i></span>
                                        </div>
                                        <select name="app" className="form-control" required>
                                            <option value="contract">العقود</option>
                                            <option disabled value="invoicing">الفواتير</option>
                                            <option disabled value="auditSystem">نظام المراجعة</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-group">
                                        <div className="g-recaptcha" data-sitekey="6LeTnXYkAAAAAOKn08dIDxnwwcCtrn6j4jhT3c_D" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                        
                                    </div>
                                </div>
                                <ReCAPTCHA
                                    sitekey="6LeTnXYkAAAAAOKn08dIDxnwwcCtrn6j4jhT3c_D"
                                    ref={recaptchaRef}
                                />
                                    <div className="col-sm-12">
                                    {sendSucess == true && 
                                        <div class="alert alert-success mt-4" role="alert">
                                        تم استلام طلبك بنجاح
                                        </div>
                                    }
                                    {sendError == true &&
                                        <div class="alert alert-danger mt-4" role="alert">
                                        حدث خطأ اثناء تسجيل طلبك نرجو مراجعة البيانات المدخلة
                                        </div>
                                    }
                                    {sendLoading == true && 
                                        <div class="alert alert-warning mt-4" role="alert">
                                        جاري معالجة طلبك ...
                                        </div>
                                    }
                                    </div>
                                <div className="col-sm-12 mt-2">
                                    <button name="submit" type="submit" value="Submit" className="btn btn-link d-inline-flex align-items-center"><i className="fa fa-angle-left m-l10"></i>التسجيل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl-6 col-lg-5 m-b30">
                        <div className="dlab-media cf-r-img">
                            <Image width={786} height={586}  src={"https://audit-website.s3.us-east-1.amazonaws.com/"+item.image} className="move-1" alt={item.title} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>    
    )
}

export default Register;